import { Box, Container } from "@mui/material";
import { Header } from "../../components/Header";
import { Partners } from "../../components/Partners";
import { HistoryDetail } from "../../components/HistoryDetail";
import { Footer } from "../../components/Footer";
// import { ThemeSelector } from "../../components/ThemeSelector";
import { useThemes } from "../../hooks/useThemes";
import { Awards } from "../../components/Awards";

const Home = () => {
  const { themeMode } = useThemes();

  return (
    <Container
      sx={{
        maxWidth: 900,
        backgroundImage:
          themeMode === "dark"
            ? "linear-gradient(62deg, #0D324D 0%, #7F5A83 100%)"
            : "linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Header />
        <Awards />
        <Partners />
        <HistoryDetail />
        <Footer />
      </Box>
    </Container>
  );
};

export default Home;
