import { styled, Typography } from "@mui/material";

export const Footer = () => {
  return (
    <T.Container>
      <div>
        <Typography variant="caption">
          상호: 주식회사 패스메디, 대표자: 류근문, 문의: contact@pathmedi.com
        </Typography>
      </div>
      <div>
        <Typography variant="caption">
          주소: 인천광역시 남동구 남동대로215번길 30, 7층 711-17호(고잔동,
          인천종합비즈니스센터)
        </Typography>
      </div>
    </T.Container>
  );
};

const T = {
  Container: styled("div")(({ theme }) => ({
    color: theme.palette.text.disabled,
    padding: "6rem 0",
  })),
};
