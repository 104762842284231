export interface History {
  title: string;
  date: string;
  images?: {
    url: string;
    desc: string;
  }[];
}

export const histories: History[] = [
  {
    title: "주식회사 패스메디(PathMedi Corp.) 설립",
    date: "2021.03.24",
  },
  {
    title: "ITP 인천종합비즈니스센터(중장년) 입주 확정",
    date: "2021.03.25",
  },
  {
    title: "프로젝트 - 모바일 서명 개발",
    date: "2021.04",
  },
  {
    title: "병리 병의원용 공정 관리 솔루션(SaaS)",
    date: "2021.05 ~ 2022.12",
    images: [
      {
        url: "/images/projects/2_validate-history.png",
        desc: "접수데이터 검증 화면",
      },
      {
        url: "/images/projects/3_process_reception.png",
        desc: "조직접수 화면",
      },
      {
        url: "/images/projects/4_process_gross-ledger.png",
        desc: "그로스장부 화면",
      },
      {
        url: "/images/projects/5_process_gross-input.png",
        desc: "그로스입력 화면",
      },
      {
        url: "/images/projects/6_process_assign.png",
        desc: "어사인 화면",
      },
      {
        url: "/images/projects/7_process_diagnosis.png",
        desc: "어사인 화면",
      },
      {
        url: "/images/projects/8_process_gynecology_diagnosis.png",
        desc: "부인과진단입력 화면",
      },
      {
        url: "/images/projects/8_process_gynecology_result.png",
        desc: "부인과완료 화면",
      },
      {
        url: "/images/projects/9_process_non-gynecology_diagnosis.png",
        desc: "비부인과진단입력 화면",
      },
      {
        url: "/images/projects/9_process_non-gynecology_reception.png",
        desc: "비부인과접수 화면",
      },
      {
        url: "/images/projects/9_process_non-gynecology_result.png",
        desc: "비부인과완료 화면",
      },
      {
        url: "/images/projects/10_process_unsolved-cases.png",
        desc: "미해결케이스 화면",
      },
      {
        url: "/images/projects/11_process_medical-charge.png",
        desc: "개별수가관리 화면",
      },
      {
        url: "/images/projects/12_process_worklist.png",
        desc: "워크리스트 화면",
      },
      {
        url: "/images/projects/13_process_exam-management.png",
        desc: "검사항목 화면",
      },
      {
        url: "/images/projects/14_statistics_s-daily.png",
        desc: "통계 화면",
      },
      {
        url: "/images/projects/15_management_branch.png",
        desc: "영업소관리 화면",
      },
      {
        url: "/images/projects/15_management_hospital.png",
        desc: "병원관리 화면",
      },
      {
        url: "/images/projects/15_management_last-receipt-date-by-hospital.png",
        desc: "병원별최근 접수일 화면",
      },
      {
        url: "/images/projects/15_management_poc.png",
        desc: "영업담당자관리 화면",
      },
      {
        url: "/images/projects/16_process_integrated-search.png",
        desc: "통합검색 화면",
      },
      {
        url: "/images/projects/17_process_tat.png",
        desc: "TAT 화면",
      },
    ],
  },
  {
    title: "씨젠의료재단 병리 데이터 연동 프로젝트 1 ~ 2차",
    date: "2021.09 ~ 2021.12",
    images: [
      {
        url: "/images/projects/seegene.png",
        desc: "씨젠 Link 관제 및 제어 프로그램 기동 화면",
      },
    ],
  },
  {
    title:
      "건국대학교병원 KUH-FTS 대용량 환자 정보 업로드 시스템 개발 프로젝트",
    date: "2022.01 ~ 2022.04",
    images: [
      {
        url: "/images/projects/kuh-fts.png",
        desc: "Backend Swagger Open Api document 화면",
      },
    ],
  },
  {
    title: "건국대학교병원 소아 당뇨 시스템 개발 프로젝트",
    date: "2022.05 ~ 2022.08",
    images: [
      {
        url: "/images/projects/diabetes-1.png",
        desc: "3차원 시뮬레이션 화면",
      },
      {
        url: "/images/projects/diabetes-2.png",
        desc: "2차원 당뇨 존(Zone) 판정 화면 ",
      },
      {
        url: "/images/projects/diabetes-3.png",
        desc: "Axis control 및 Zone control 화면",
      },
    ],
  },
  {
    title: "누리플렉스 전력 통합 모니터링 시스템 개발 프로젝트",
    date: "2022.05 ~ 2022.08",
    images: [
      {
        url: "/images/projects/nuriflex.png",
        desc: "전라남도 순천 사이트 관제 및 제어 화면",
      },
    ],
  },
  {
    title: "누리플렉스 RMS, PMS 말레이시아 사와락 전력청 개발 프로젝트(수출용)",
    date: "2022.10 ~ 2022.12",
    images: [
      // "/images/projects/nuri-malaysia/1.png",
      {
        url: "/images/projects/nuri-malaysia-5.png",
        desc: "발전소 관제 운영 대쉬 보드 화면",
      },
      {
        url: "/images/projects/nuri-malaysia-2.png",
        desc: "운영 운전 이력 조회 화면",
      },
      {
        url: "/images/projects/nuri-malaysia-3.png",
        desc: "디바이스 운전 제어 화면",
      },
      {
        url: "/images/projects/nuri-malaysia-4.png",
        desc: "디바이스 별 운영 현황 및 차트 화면",
      },
    ],
  },
];
