import { atom } from "recoil";
import {
  AvailableThemeModeType,
  getDefaultThemeMode,
} from "../utils/createTheme";

export const themeModeState = atom<AvailableThemeModeType>({
  key: "theme/mode",
  default: getDefaultThemeMode(),
});
