export type PartnerType = {
  name: string;
  logoUrl: string;
  href: string;
};

export const partners: PartnerType[] = [
  {
    name: "인천종합비즈니스센터",
    logoUrl: "/images/partners/itp.jpeg",
    href: "https://www.itp.or.kr/intro.asp?tmid=86",
  },
  {
    name: "씨젠의료재단",
    logoUrl: "/images/partners/seegene.png",
    href: "https://pr.seegenemedical.com",
  },
  {
    name: "건국대학교병원",
    logoUrl: "/images/partners/kuh.png",
    href: "https://www.kuh.ac.kr",
  },
  {
    name: "누리플렉스",
    logoUrl: "/images/partners/nuri.png",
    href: "http://www.nuritelecom.com",
  },
];
