import { useCallback, useMemo } from "react";
import { useRecoilState } from "recoil";
import { themeModeState } from "../recoils/themeModeState";
import {
  themesAvailable,
  AvailableThemeModeType,
  setDefaultThemeMode,
} from "../utils/createTheme";

export const useThemes = () => {
  const [themeMode, setThemeMode] = useRecoilState(themeModeState);

  const theme = useMemo(() => {
    return themesAvailable[themeMode];
  }, [themeMode]);

  const setTheme = useCallback(
    (mode: AvailableThemeModeType) => {
      setThemeMode(mode);
      setDefaultThemeMode(mode);
    },
    [setThemeMode]
  );

  return {
    themeMode,
    theme,
    setTheme,
  };
};
