import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import App from "./App";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "./index.css";

const container = document.getElementById("root") as HTMLElement;

if (container.hasChildNodes()) {
  ReactDOM.hydrateRoot(
    container,
    <RecoilRoot>
      <App />
    </RecoilRoot>
  );
} else {
  const root = ReactDOM.createRoot(container);
  root.render(
    <RecoilRoot>
      <App />
    </RecoilRoot>
  );
}
