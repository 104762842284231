import { createTheme } from "@mui/material";
import { HelperLocalStorage } from "../helpers/localStorage";

export type AvailableThemeModeType = "dark" | "light";

const fallbackThemeMode = "dark";

const themeCreate = (mode: AvailableThemeModeType) =>
  createTheme({
    palette: {
      mode,
    },
  });

export const themesAvailable = {
  dark: themeCreate("dark"),
  light: themeCreate("light"),
};

export const getDefaultThemeMode = (): AvailableThemeModeType => {
  // return HelperLocalStorage.getThemeMode() ?? fallbackThemeMode;
  return fallbackThemeMode;
};

export const setDefaultThemeMode = (mode: AvailableThemeModeType) => {
  HelperLocalStorage.setThemeMode(mode);
};
