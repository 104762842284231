import { Chip, styled, Typography } from "@mui/material";
import { awards } from "../data/awards";

export const Awards = () => {
  return (
    <T.Container>
      {awards.map((a, idx) => {
        return (
          <T.Award key={`key-award-${idx}`}>
            <Chip
              label="🎉 경축 🎉"
              variant="outlined"
              color="secondary"
            ></Chip>
            <Typography variant="h6">{a.title}</Typography>
            <Typography variant="caption" style={{ display: "block" }}>
              {a.desc}
            </Typography>
            <Typography variant="overline">{a.date}</Typography>
          </T.Award>
        );
      })}
    </T.Container>
  );
};

const T = {
  Container: styled("div")(({ theme }) => ({
    color: theme.palette.text.secondary,
  })),
  Award: styled("div")(({ theme }) => ({
    marginBottom: "4rem",
  })),
};
