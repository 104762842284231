import { Box, Grid, styled } from "@mui/material";
import { partners } from "../data/partners";

export const Partners = () => {
  return (
    <T.Container
      sx={{
        maxWidth: {
          xs: 400,
          md: 700,
        },
      }}
    >
      <Grid container spacing={2}>
        {partners.map((p, idx) => {
          return (
            <Grid item xs={12} md={6} key={`partner-${idx}`}>
              <T.Anchor href={p.href} target="_blank" rel="noreferrer">
                <T.ImageWrapper>
                  <img src={p.logoUrl} alt={p.name} />
                </T.ImageWrapper>
              </T.Anchor>
            </Grid>
          );
        })}
      </Grid>
      <Box sx={{ padding: "1rem" }}>패스메디는 고객들과 함께 합니다.</Box>
    </T.Container>
  );
};

// Styled Components

const T = {
  Container: styled("div")(({ theme }) => ({
    boxSizing: "border-box",
    paddingTop: "2rem",
    margin: "0 auto 6rem",
    backgroundColor: theme.palette.text.disabled,
    borderRadius: 3,
  })),
  Anchor: styled("a")(({ theme }) => ({
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    width: 230,
    height: 56,
    boxSizing: "border-box",
    backgroundColor: "white",
    borderRadius: 2,
  })),
  ImageWrapper: styled("div")(({ theme }) => ({
    display: "inline",
    backgroundColor: "white",
  })),
};
