export type AwardType = {
  title: string;
  desc: string;
  date: string;
};

export const awards: AwardType[] = [
  {
    title: "중소벤처기업진흥공단 이사장 표창",
    desc: "혁신적 기업 활동으로 경제 활성화에 기여한 공으로 이사장 표창 수상",
    date: "2022.12.15",
  },
];
