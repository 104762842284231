import { styled, Typography } from "@mui/material";

export const Header = () => {
  return (
    <T.Container>
      <Typography
        component="h1"
        sx={{
          typography: { xs: "h4", sm: "h3", md: "h2" },
          color: (theme) => theme.palette.text.primary,
        }}
      >
        <p>PathMedi.com</p>
      </Typography>

      <Typography
        variant="subtitle2"
        sx={{
          fontSize: { xs: "1rem", sm: "1.2rem", md: "1.5rem" },
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        의료 전문 스타트업 <T.Company>주식회사 패스메디</T.Company>
        입니다.
      </Typography>
    </T.Container>
  );
};

const T = {
  Container: styled("div")(({ theme }) => ({
    padding: "6rem 0",
  })),
  Company: styled("span")(({ theme }) => ({
    fontSize: "1.2em",
    color: theme.palette.success.main,
  })),
};
