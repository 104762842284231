import { Box, IconButton, Modal, styled, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { histories } from "../data/history";
import CloseIcon from "@mui/icons-material/Close";

type ModalType = {
  open?: boolean;
  url?: string;
  title?: string;
  desc?: string;
};

export const HistoryDetail = () => {
  const [modal, setModal] = useState<ModalType>({});

  const handleClose = useCallback(() => {
    setModal((prev) => ({ ...prev, open: false }));
  }, []);

  const onClick = useCallback((m: ModalType) => {
    setModal((prev) => ({ ...prev, ...m }));
  }, []);

  return (
    <T.Container>
      {histories.map((history, idx) => {
        return (
          <T.History key={`history-detail-${idx}`}>
            <Typography>{history.title}</Typography>
            <Typography variant="overline">{history.date}</Typography>
            <T.ImagesWrapper>
              {history.images?.map(({ url, desc }, i) => {
                return (
                  <T.ImageWrapper key={`image-${i}`}>
                    <T.Image
                      src={url}
                      alt={history.title + "-" + i}
                      onClick={() =>
                        onClick({
                          open: true,
                          url,
                          title: history.title,
                          desc,
                        })
                      }
                    />
                  </T.ImageWrapper>
                );
              })}
              {history.images?.length === 0 && (
                <Typography>이미지 준비중입니다</Typography>
              )}
            </T.ImagesWrapper>
          </T.History>
        );
      })}
      <Modal
        open={!!modal.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          overflowY: "scroll",
        }}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 1rem)",
            maxWidth: 600,
            padding: "60px 16px 16px",
            border: "1px solid darkgray",
            borderRadius: 3,
            boxShadow: 24,
            backdropFilter: "blur(10px)",
            outline: 0,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              backgroundColor: (theme) => theme.palette.background.paper,
              border: "2px solid gray",
              "&:hover": {
                backgroundColor: (theme) => theme.palette.background.paper,
                borderWidth: "3px",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            component="img"
            src={modal.url ?? ""}
            alt="detailed photo"
            sx={{
              width: "100%",
              border: "1px solid gray",
              boxSizing: "border-box",
            }}
          />
          <Box
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "dark"
                  ? "rgba(0, 0, 0, 0.3)"
                  : "rgba(255, 255, 255, 0.3)",
              backdropFilter: "blur(1px)",
              p: 2,
            }}
          >
            <Typography id="modal-modal-description">{modal.desc}</Typography>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mt: 4 }}
            >
              {modal.title} 이미지
            </Typography>
          </Box>
        </Box>
      </Modal>
    </T.Container>
  );
};

const T = {
  Container: styled("div")(({ theme }) => ({
    color: theme.palette.text.secondary,
  })),
  History: styled("div")(({ theme }) => ({
    marginBottom: "4rem",
  })),
  ImagesWrapper: styled("div")(({ theme }) => ({
    color: theme.palette.text.disabled,
    maxWidth: "1000px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    margin: "auto",
  })),
  ImageWrapper: styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  })),
  Image: styled("img")(({ theme }) => ({
    marginTop: "0.5rem",
    width: 200,
    border: `1px solid ${theme.palette.text.disabled}`,
  })),
};
