import { CssBaseline, ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import { useThemes } from "./hooks/useThemes";
import Home from "./pages/home/Home";
import "./App.scss";

function App() {
  const { theme, themeMode } = useThemes();

  useEffect(() => {
    document.documentElement.setAttribute("data-color-scheme", themeMode);
  }, [themeMode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Home />
    </ThemeProvider>
  );
}

export default App;
