import { THEME_MODE } from "../constants/global";
import { AvailableThemeModeType } from "../utils/createTheme";

export class HelperLocalStorage {
  static getItem(key: string) {
    return localStorage.getItem(key);
  }
  static setItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }
  static removeItem(key: string) {
    localStorage.removeItem(key);
  }

  static getThemeMode() {
    return HelperLocalStorage.getItem(
      THEME_MODE
    ) as AvailableThemeModeType | null;
  }
  static setThemeMode(value: AvailableThemeModeType) {
    HelperLocalStorage.setItem(THEME_MODE, value);
  }
  static removeThemeMode() {
    HelperLocalStorage.removeItem(THEME_MODE);
  }
}
